import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://49bed617303040ae988bb271ef886483@o555412.ingest.us.sentry.io/5685898",
  debug: false,
  environment: "development",
  release: "a8e7bdeb856037ff1f00d56ba6b28b12f85d8ce3",
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});